import React from "react";
import linkedIn from '../assets/linkedin.png'
import gitHub from '../assets/github.png';
import profile from '../assets/nadim_profile.png';
import cv from '../assets/resume.pdf';

function Hero() {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = cv;
    link.download = cv.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleContactClick = () => {
    window.location.href = '#contact';
  };

  return (
    <>
      <section id="profile">
        <div className="section__pic-container">
          <img src={profile} alt="Nadim Anwar profile" />
        </div>
        <div className="section__text">
          <p className="section__text__p1">Hello, I'm</p>
          <h1 className="title">Mohammed Nadim Anwar</h1>
          <p className="section__text__p2">Frontend Developer</p>
          <div className="btn-container">
            <button onClick={handleDownload}
              className="btn btn-color-2"
            >
              Download CV
            </button>
            <button
              className="btn btn-color-1"
              onClick={handleContactClick}
            >
              Contact Info
            </button>
          </div>
          <div id="socials-container">
            <a href="https://www.linkedin.com/in/nadim-anwar">
            <img
              src={linkedIn}
              alt="My LinkedIn profile"
              className="icon"
            />
            </a>
            <a href="https://github.com/anwarnadim7">
            <img
              src={gitHub}
              alt="My Github profile"
              className="icon"
            />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
