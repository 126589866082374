import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import "./App.css";
import "./mediaqueries.css"
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <NavBar />
      <Hero />
      <About/>
      <Experience/>
      <Projects/>
      <Contact/>
      <Footer/>
    </>
  );
}

export default App;
