import React from 'react'
import linkedIn from '../assets/linkedin.png';
import mail from '../assets/email.png';

function Contact() {
  return (
    <>
    <section id="contact">
      <p className="section__text__p1">Get in Touch</p>
      <h1 className="title">Contact Me</h1>
      <div className="contact-info-upper-container">
        <div className="contact-info-container">
          <img
            src={mail}
            alt="Email icon"
            className="icon contact-icon email-icon"
          />
          <p><a href="mailto:nadimanwar.mail@gmail.com">nadimanwar.mail@gmail.com</a></p>
        </div>
        
        <div className="contact-info-container">
          <img
            src={linkedIn}
            alt="LinkedIn icon"
            className="icon contact-icon"
          />
          <p><a href="https://www.linkedin.com/in/nadim-anwar/">LinkedIn</a></p>
        </div>
      </div>
    </section>
    </>
  )
}

export default Contact